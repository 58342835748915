import styled from 'styled-components';
import { NavLink } from '@remix-run/react';

export const Root = styled.div`
  && {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    position: relative;
    min-height: -webkit-fill-available;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const NavigationRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.5625rem;
  width: 100%;
  position: fixed;
  background-color: transparent;
  z-index: ${({ theme }) => theme.zIndex.header};
  align-items: center;
`;

export const NavigationItem = styled(NavLink)<{
  color?: string;
  fontColorHover?: string;
  fontWeight?: number;
}>`
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.color ?? props.theme.colors.primaryDark};
  cursor: pointer;
  display: block;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 900)};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4475rem;
  letter-spacing: 0.0094rem;
  transition: color 0.2s ease-in;

  &:hover {
    color: ${({ theme, fontColorHover }) =>
      fontColorHover ?? theme.colors.textHover};
  }

  &.active {
    color: ${({ theme }) => theme.colors.textActive};
  }
`;

export const MainContentWrapper = styled.main`
  padding: 0 5.625rem;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 2rem;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 0 1rem;
  }
`;
